import React, { Component } from "react";
import { graphql, navigate, Link } from "gatsby";
import Img from "gatsby-image";
import moment from "moment";
import { DiscussionEmbed } from "disqus-react";
import { parse as parsequery }from "query-string";


import Layout from "../components/layout";
import SEO from "../components/seo";
import Share from "../components/share";

let position = 0;
let toggle = true;

export default class blogPost extends Component {
  constructor(props) {
    super();
    const isSong = !!props.data.contentfulBlogs.albumname;
    const {v} = parsequery(props.location.search);
    let menu = v || "lyrics";
    menu = isSong ? menu : "description";

    this.state = { menu: menu, isSong, position: { current: 0, prev: 0 } };
  }


  render() {
    const data = this.props.data.contentfulBlogs;
    const disqusShortname = "RohitGupta";
    const disqusConfig = {
      identifier: data.id,
      title: data.title
    };

    const siteurl = this.props.data.contentfulSiteInformation.siteUrl + "/";
    const twiteerhandle = this.props.data.contentfulSiteInformation.twiteerHandle;
    const socialConfigss = {
      site: {
        siteMetadata: { siteurl, twiteerhandle }
      },
      title: data.title,
      slug: data.slug
    };

    const smn /* selectMenu */ = (e, v) => {
      if (e.currentTarget.checked)
        this.setState(() => {
          const qs = v;
          navigate("?v=" + qs, {replace:true});
          return { menu: v };
        });
    };

    return (
      <Layout>
        <SEO
          title={data.title}
          keywords={[`風の中`, `ロックバンド`, `邦楽ロック`, `唄`, `ギターロック`, `${data.title}`]}
        />
        <div className="site-container blog-post">
          <div className="container">
            {data.featureImage && !this.state.isSong ? (
              <Img
                className="feature-img"
                fixed={data.featureImage.fluid}
                imgStyle={{objectFit: "contain"}}
                objectPosition="50% 50%"
              />
            ) : (
              <div className="no-image"></div>
            )}

            <div className="details">
              <h1 className="title">{data.title}</h1>

              {data.albumname && (
                <span className="date">
                  inclueded in <Link to={data.albumslug}>{data.albumname}</Link>
                </span>)}
              <AudioLink appleMusic={data.applemusic} spotify={data.spotify} youtube={data.youtube} />

              { this.state.isSong
                ? <SongMenus data={data} smn={smn} menu={this.state.menu} />
                : <AlbumMenus data={data} smn={smn} menu={this.state.menu} />
              }

              {/* コンテンツ */}
              {this.state.menu === "lyrics" && (
                <Content d={data.lyrics && data.lyrics.childMarkdownRemark.html} />
              )}
              {this.state.menu === "poetry" && (
                <Content d={data.essays && data.essays.childMarkdownRemark.html} />
              )}
              {this.state.menu === "video" && (
                <Content
                  d={data.youtube && createYoutubeEnbedTag(data.youtube)}
                  by={{director: data.director, starring: data.starring}}
                />
              )}

              {this.state.menu === "description" && (
                <Content
                  d={data.description && data.description.childMarkdownRemark.html}
                  by={{
                    writtenBy: data.writtenBy,
                    arrangedBy: data.arrangedBy,
                    recordedBy: data.recordedBy,
                    mixedBy: data.mixedBy,
                    specialThanks: data.specialThanks,
                  }}
                />
              )}

            </div>
            <Share
              socialConfig={{
                ...socialConfigss.site.siteMetadata.twiteerhandletitle,
                config: {
                  url: `${siteurl}${socialConfigss.slug}`,
                  title: `${socialConfigss.title}`
                }
              }}
            />
            {/*
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />*/}
          </div>
        </div>
      </Layout>
    );
  }
}

const SongMenus = ({data, smn, menu}) => (
  <div className="menus">
    <ul>
      <li key="1">
        <MenuItem id="mLyrics" label="lyrics" menu="lyrics" checked={menu === "lyrics"} smn={smn} />
      </li>
      {data.essays &&
      <li key="2">
        <MenuItem id="mPoetry" label="poetry" menu="poetry" checked={menu === "poetry"} smn={smn} />
      </li>
      }
      {data.youtube && (
        <li key="3">
          <MenuItem id="mVideo" label="video" menu="video" checked={menu === "video"} smn={smn} />
        </li>
      )}
    </ul>
  </div>
);

const AlbumMenus = ({data, smn, menu}) => (
  <div className="menus">
    <ul>
      <li key="1">
        <MenuItem id="mDescription" label="description" menu="description" checked={menu === "description"} smn={smn} />
      </li>
      {data.essays &&
      <li key="2">
        <MenuItem id="mPoetry" label="poetry" menu="poetry" checked={menu === "poetry"} smn={smn} />
      </li>
      }
      {data.video && (
        <li key="3">
          <input type="radio" name="menu" value="video" id="radioVideo" />
          <label for="raidoVideo">
            <span>video</span>
          </label>
        </li>
      )}
    </ul>
  </div>
);

const MenuItem = ({id, label, checked, menu, smn}) => (
  <>
    <input type="radio" name="menu" id={id} onChange={e => smn(e, menu)} defaultChecked={checked} />
    <label htmlFor={id}>
      <span>{label}</span>
    </label>
  </>
)

const Content = ({d, className, by = {}}) => d &&
<>
  <div dangerouslySetInnerHTML={{__html: d}} />
  <br/>
  <AlbumStyles />
  <ByTitle titles={ByList} />
  {
    ByList.map(b => by[b] &&
      <>
        <ul className={`by ${b}`}>
          {by[b].map(s => <li key={b}>{s}</li>)}
        </ul>
      </>
    )
  }
</>

const AudioLink = ({appleMusic, spotify, youtube}) => {
  return (
    <div className="socials">
      <ul className="social">
        {appleMusic && (
          <li key="1">
            <a
              className="fab fa-apple"
              href={appleMusic}
              target="_blank"
              rel="noopener noreferrer"
            />
          </li>
        )}
        {spotify && (
          <li key="2">
            <a
              className="fab fa-spotify"
              href={spotify}
              target="_blank"
              rel="noopener noreferrer"
            />
          </li>
        )}
        {youtube && (
          <li key="3">
            <a
              className="fab fa-youtube"
              href={youtube}
              target="_blank"
              rel="noopener noreferrer"
            />
          </li>
        )}
      </ul>
    </div>
  );
};

const createYoutubeEnbedTag = (url) => {

  const id = url.split("v=")[1];
 return `<iframe src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
}
const ByList = ["writtenBy", "arrangedBy", "recordedBy", "mixedBy", "specialThanks", "director", "starring"];
export const pageQuery = graphql`
  query SinglePostQuery($slug: String!) {
    contentfulBlogs(slug: { eq: $slug }) {
      id
      title
      slug
      featureImage {
        fluid(maxWidth: 1500) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
      albumname
      applemusic
      albumslug
      spotify
      youtube
      description {
        childMarkdownRemark {
          html
        }
      }
      lyrics {
        childMarkdownRemark {
          html
        }
      }
      essays {
        childMarkdownRemark {
          html
        }
      }
      writtenBy
      arrangedBy
      recordedBy
      mixedBy
      director
      starring
      specialThanks
      createdAt
    }
    contentfulSiteInformation {
      siteUrl
      twiteerHandle
    }
  }
`;

const AlbumStyles = () => (
  <style dangerouslySetInnerHTML={{
      __html:`
      ul.by{
        position: relative;
        padding: 15px 15px 15px 30px;
        font: 14px/1.6 'arial narrow', sans-serif;
        border: solid 2px #717172;
        border-radius:8px;
        color: #34344a;
        max-width:340px;
        width: auto;
        background: #fff;
      }
      ul.by:before{
        position: absolute;
        display: block;
        top: -15px;
        left: 20px;
        background: #fff;
        font-size: 16px;
        font-weight: bold;
        padding: 0 10px;
      }
      ul.li{
        font-weight: bold;
      }
      `
  }} >
  </style>
)

const ByTitle = ({titles}) => (
  <style dangerouslySetInnerHTML={{
      __html: titles.map(title => `ul.${title}:before{content: "${title}";}`).join('\n')
  }} >
  </style>
)
